import React from "react";
import "./App.css";
import logo from "./img/logo.png";
import { Link } from "react-scroll";
import { Helmet } from "react-helmet";

import Card from "./components/card";
import Enquire from "./components/enquire";
import Footer from "./components/footer";
import About from "./img/about.jpg";
import faceMask from "./img/faceMask.jpg";
import maskMock from "./img/maskMock.jpg";
import Portfolio from "./components/portfolio";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>RG Digital Printing</title>
        <meta
          name="description"
          content="RG Printing Inc., with over 25 years of experience, we have been delivering quality
              of products and services to every individual who walks into our
              place. Our hospitality and attention to details has led the walk
              in clients to become repeated customers. Our professional in-house
              design that elevates our client ideas into visual masterpieces. We
              take pride in serving everyone from small business to trade
              printers by conducting the right business with the right attitude!"
        />
      </Helmet>
      <header>
        <div className="logo">
          <img src={logo} alt="RG Printing Logo" />
        </div>
        <nav className="navigation">
          <ul>
            <li>
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="portfolio"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Enquiries
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="container">
        <section id="home">
          <img src={About} alt="RG Printing Banner" />
        </section>
        <section id="about">
          <h1>About Us</h1>
          <div>
            <div className="aboutImg"></div>
            <p>
              With over 25 years of experience, we have been delivering quality
              of products and services to every individual who walks into our
              place. Our hospitality and attention to details has led the walk
              in clients to become repeated customers. Our professional in-house
              design that elevates our client ideas into visual masterpieces. We
              take pride in serving everyone from small business to trade
              printers by conducting the right business with the right attitude!
            </p>
          </div>
        </section>
        <section id="services">
          <h1>Services</h1>
          <div className="masks">
            <div className="maskMock">
            <img src={maskMock} alt="Face mask mockup" className="maskM"/>
            <img src={faceMask} alt="Face mask example"/>
            <p>Venturing into Customized Embroidered face masks.<br></br>Minimum order 100.<br></br>Please email rgprinting@on.aibn.com for price details.</p>
            </div>
          </div>
          <div>
            <Card img="covid" />
            <Card img="vinyl" />
            <Card img="trifold" />
            <Card img="cards" />
            <Card img="signs" />
            <Card img="banners" />
            <Card img="folder" />
            <Card img="menu" />
            <Card img="book" />
          </div>
        </section>
        <Portfolio />
        <section id="contact">
          <h1>Enquiries</h1>
          <Enquire />
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default App;
