import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";

const Footer = (props) => {
	return (
		<footer style={{ display: "flex" }}>
			<div>
				<h5>Rg Printing</h5>
				<h6>Unit 6 - 3001 Markham Rd, Scarborough, ON M1X 1L6</h6>
				<h6> Mon - Fri: 10am - 5pm EST</h6>
				<h6>Phone: (416) 293-9163</h6>
				<h6>Copyright © 2023 RG Printing Inc., All Rights Reserved.</h6>
			</div>
			<div
				className="icons"
				style={{
					marginTop: "175px",
				}}
			>
				<a
					href="https://www.facebook.com/RG-Printing-Inc-108180060987901"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FacebookIcon style={{ color: "white", fontSize: "40px" }} />
				</a>
			</div>
		</footer>
	);
};

export default Footer;
