import React from "react";
import cards from "../img/cards.jpg";
import banners from "../img/banner.jpg";
import signs from "../img/sign.jpg";
import book from "../img/book.jpg";
import folder from "../img/folder.jpg";
import menu from "../img/menu.jpg";
import covid from "../img/covid.jpg";
import vinyl from "../img/vinyl.jpg";
import trifold from "../img/trifold.jpg";

const Card = (props) => {
  let image;
  if (props.img === "cards") {
    image = cards;
  } else if (props.img === "signs") {
    image = signs;
  } else if (props.img === "banners") {
    image = banners;
  } else if (props.img === "menu") {
    image = menu;
  } else if (props.img === "folder") {
    image = folder;
  } else if (props.img === "book") {
    image = book;
  } else if (props.img === "covid") {
    image = covid;
  } else if (props.img === "vinyl") {
    image = vinyl;
  } else if (props.img === "trifold") {
    image = trifold;
  }

  return (
    <div className="card">
      <img src={image} alt={props.img} />
    </div>
  );
};

export default Card;
