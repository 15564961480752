import React, { useState } from "react";
import emailjs from "emailjs-com";
import { animateScroll as scroll } from "react-scroll";

const Enquire = (props) => {
  let [email, setEmail] = useState("");
  let [subject, setSubject] = useState("");
  let [content, setContent] = useState("");

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const handleChange = (event) => {
    if (event.target.name === "email") {
      setEmail(event.target.value);
    } else if (event.target.name === "subject") {
      setSubject(event.target.value);
    } else if (event.target.name === "content") {
      setContent(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert("Press OK to send Enquiry");
    emailjs
      .send(
        "gmail",
        "template_5nDKUziy",
        { from_name: subject, from_email: email, message_html: content },
        "user_ylO826sTBGKDQTCOttjK7"
      )
      .then((res) => document.getElementById("enquire-form").reset())
      .then((res) => scrollToTop())
      .catch((err) => console.log(err));
  };

  return (
    <div className="enquire">
      <form id="enquire-form" onSubmit={handleSubmit}>
        <label>Name</label>
        <input
          type="text"
          name="subject"
          onChange={handleChange}
          placeholder="Name"
          required
        />
        <label>Email</label>
        <input
          type="email"
          name="email"
          onChange={handleChange}
          placeholder="your@email.com"
          required
        />
        <label>Your Message</label>
        <textarea
          rows="5"
          cols="25"
          name="content"
          style={{ resize: "none" }}
          onChange={handleChange}
          placeholder="Enter your message"
          required
        ></textarea>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Enquire;
