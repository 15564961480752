import React from "react";

import cloth from "../img/portfolio/cloth.jpeg";
import furEndly from "../img/portfolio/furreEndly.jpg";
import furEndly2 from "../img/portfolio/furreEndly2.jpg";
import procore from "../img/portfolio/procore.mp4";
import procoreCard from "../img/portfolio/procoreCard.mp4"

const Portfolio = (props) => {
    return (
        <section id="portfolio">
            <h1>Portfolio</h1>
            <div>
                <div className="images">
                    <img src={cloth} alt="Printing on clothing" title="Printing on clothing" />
                    <img src={furEndly} alt="Sign Printing" title="Sign Printing" />
                    <img src={furEndly2} alt="Sign Printing" title="Sign Printing" />
                </div>
                <div className="videos">
                    <video src={procore} autoPlay muted loop/>
                    <video src={procoreCard} autoPlay muted loop/>
                </div>
            </div>
        </section>
    )
}

export default Portfolio;